body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo{
  mix-blend-mode: multiply ;
  width: 60px;
  height: 60px;
}
.bg1{
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vh;
}

.canvas {
  border: 1px solid red;
  width: 100%;
  height: 100vh;
}
.drawing-board{
  padding-left: 20%;
  padding-top: 5%;
}
.carousel {
   width: 30rem;
   height: 30rem;
   position: relative;
   margin: 0 auto;
   perspective: 11rem;
 }

.carousel__container {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 3s;
}

.carousel__slide {
  position: absolute;
  width: 28rem;
  height: 28rem;
  left: 0.625rem;
  top: 0.625rem;
  transition: transform 3s, opacity 3s;
}

.carousel__slide img {
  width: 100%;
  height: 100%;
  display: block;
  border: 3px solid transparent;
  border-image: linear-gradient(144deg, darkgreen, darkolivegreen 50%, greenyellow);
  border-image-slice: 2;
  -webkit-box-reflect: below 75px linear-gradient(to bottom right, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
  border-image-slice: 2;
  padding: 25px;
}

.carousel__slide-overlay {
  color: #fff;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.carousel__slide-overlay span,
.carousel__slide-overlay strong {
  display: block;
  flex: 0 0 100%;
}

.carousel__slide:hover .carousel__slide-overlay {
  opacity: 1;
}

.carousel__controls {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.carousel__control {
  font-size: 0;
  line-height: 0;
  text-indent: -100%;
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  position: relative;
  border: 1px solid;
  margin: 0 2rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  box-shadow: none;
  display: none;
}

.carousel__control::before,
.carousel__control::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.carousel__control::before {
  width: 50%;
  height: 1px;
  background-color: black;
}

.carousel__control::after {
  content: '';
  width: 0.9375rem;
  height: 0.9375rem;
  display: inline-block;
  vertical-align: middle;
  border-color: #fff;
  border-style: solid;
  border-width: 0 0 1px 1px;
  margin: auto;
  transform: rotate(-135deg);
  transform-origin: 50% 50%;
  transition: all 0.5s ease-in-out;
}

.carousel__control--prev::after {
  transform: rotate(45deg);
}

.border-gradient {
  width: 400px;
  border: 3px solid transparent;
  border-image: linear-gradient(to right, green, lightgreen);
  border-image-slice: 1;
  padding: 25px;
}

.primary-gradient, .button-primary{
  background-image: linear-gradient(144deg, darkgreen, darkolivegreen 50%, greenyellow);
  border: 2px solid white;
  border-image-slice: 2;
}
/* CSS */
.button-primary {
  align-items: center;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans;
  font-size: 30px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 19px 24px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button-primary:active,
.button-primary:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-primary {
    font-size: 24px;
    min-width: 196px;
  }
}
.threejs-container{
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: transparent;
  pointer-events: none;
}
.login-container{
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
}
.signin-container{
  z-index: -9999;
  position: fixed;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./resources/landscape.png');
}
